import { useEffect } from 'react';
import Head from 'next/head';
import Link from 'next/link';

import Line from 'components/Line';
import { withPageStaticProps } from 'utils/header';

const FourOhFour = () => {
  useEffect(() => {
    window.analytics.track('404 Error');
  }, []);

  return (
    <>
      <Head>
        <title>{'404 - Page Not Found'}</title>
        <meta name="description" key="description" content={'page not found'} />
      </Head>

      <div className="m-section-hero container flex flex-wrap items-center justify-around">
        <img
          alt="404"
          height="485px"
          id="page-not-found-img"
          src="https://gentux.imgix.net/1550091090_404-error-image.png?auto=format"
          width="250px"
        />

        <div className="mb-32">
          <h1 className="text-h1-display">{'404 Error'}</h1>

          <p>{"The page you're looking for does not exist."}</p>

          <Line />

          <Link href="/">
            <a className=" btn-info btn">{'Go Home'}</a>
          </Link>
        </div>
      </div>
    </>
  );
};

export const getStaticProps = withPageStaticProps();

export default FourOhFour;
